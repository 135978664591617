import * as React from "react";
import { Switch, Route, Redirect, RouteChildrenProps } from "react-router-dom";
import { CenterFullScreen, Lazy } from "../utility";
import HandleIncorrectRoute from "./HandleIncorrectRoute";
import { OnboardRouter } from "../onboard";
import ProtectedRoute from "./ProtectedRoute";
import { useSetMediaDomain } from "../../util/Split/listener";
import { forceLogoutViaPath } from "../../util/cookies/methods";
import { setReferralCodesToStore } from "../../services/referralCodes";
import { DesignProfileFlowContainer } from "../DesignProfileFlowContainer";
import { Page } from "../../types/DesignProfile";
import { UserCtx } from "../../util";
import { useImpactRadiusIdentifier } from "../../util/hooks/useImpactRadiusIdentifier";
import { PackageFlowPaymentConfirmedPage } from "../../pages/packageFlow/PackageFlowPaymentConfirmedPage";
import { PackageFlowCheckoutPage } from "../../pages/packageFlow/PackageFlowCheckoutPage";
import { PackageCreateAccountPage } from "../../pages/packageFlow/packageCreateAccountPage";
import { getDesignProfilePath } from "../../pages/designProfile/util/getDesignProfilePath";
import { setPromotionalCode } from "../../slices/checkoutSlice";
import { setModifiedPrice } from "../../slices/productSlice";
import { useDispatch } from "react-redux";
import { DesignProfileProvider } from "../designProfile";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import { useCurrentDesignProfileVersion } from "../../util/hooks/useCurrentDesignProfileVersion";
import { useTreatment } from "@yardzen-inc/react-split";
import {
  AA_TEST,
  PROMO_CODE_FROM_DESIGN_PROFILE,
  SHOW_DESIGN_PROFILE_QUIZ_V9_WITH_MAP,
  NEW_DESIGN_PROFILE_QUIZ,
} from "../../util/Split/splitTreatments";
import { setProductsToStore } from "../../services/getProducts";
import { PackagesGuard } from "./guards/PackageGuard";
import { useEffect } from "react";
import { emailCordialSubmit } from "../../pages/designProfile/util/emailCordialSubmit";
import Cookies from "js-cookie";

export interface AppRouterProps {}

let initialMountComplete = false;

const AppRouter: React.FC<AppRouterProps> = (props: AppRouterProps) => {
  const user = React.useContext(UserCtx);
  useSetMediaDomain();
  const dispatch = useDispatch();

  useImpactRadiusIdentifier({
    customerId: user?.uid,
    customerEmail: user?.email,
  });

  useEffect(() => {
    if (!initialMountComplete) {
      setProductsToStore();
      setReferralCodesToStore();
      dispatch(setModifiedPrice({ modifiedPrice: null }));
      dispatch(setPromotionalCode(null));
      initialMountComplete = true;
    }
  }, [dispatch]);

  // This is an A/A test to ensure the accuracy of the split.io software with our data
  useTreatment(AA_TEST);
  const showPromoCodeFromDesignProfileSplit =
    useTreatment(PROMO_CODE_FROM_DESIGN_PROFILE) || false;
  const showV9 = useTreatment(SHOW_DESIGN_PROFILE_QUIZ_V9_WITH_MAP);
  const showNewDesignProfileQuiz = useTreatment(NEW_DESIGN_PROFILE_QUIZ);
  const currentDesignProfileVersion = useCurrentDesignProfileVersion(showV9);
  const { paths: designProfilePaths } = getDesignProfilePath(
    currentDesignProfileVersion
  );
  const newQuizUrl =
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("dogfood")
      ? "https://staging.project.yardzen.com/design-consultation"
      : "https://project.yardzen.com/design-consultation";

  const queryParams = new URLSearchParams(window.location.search);
  const newQuizEnable = queryParams.get("new-quiz-enable")
    ? queryParams.get("new-quiz-enable")
    : null;
  console.log("newQuizEnable", newQuizEnable);
  const quizEmail = queryParams.get("design-profile-quiz-email")
    ? queryParams.get("design-profile-quiz-email")
    : null;

  if (quizEmail) {
    Cookies.set("yzQuizEmail", quizEmail);
    console.log("Sending e-mail to cordial - " + quizEmail);
    emailCordialSubmit(quizEmail, showPromoCodeFromDesignProfileSplit);
  }

  return (
    <Switch>
      {typeof showV9 !== "boolean" ? (
        <GenericLoadingSpinner />
      ) : (
        designProfilePaths.map((page: Page, index: number) => (
          <Route
            key={index}
            path={`/design-profile/${page.path}`}
            component={() =>
              showNewDesignProfileQuiz || newQuizEnable ? (
                (window.location.replace(newQuizUrl), null)
              ) : (
                <DesignProfileFlowContainer
                  page={page}
                  user={user}
                  designProfileVersionId={currentDesignProfileVersion}
                />
              )
            }
          />
        ))
      )}

      <Route
        exact
        path="/"
        component={() =>
          showNewDesignProfileQuiz || newQuizEnable ? (
            (window.location.replace(newQuizUrl), null)
          ) : (
            <Redirect to="/design-profile" />
          )
        }
      />
      <Route
        path="/design-profile"
        component={() =>
          showNewDesignProfileQuiz || newQuizEnable ? (
            (window.location.replace(newQuizUrl), null)
          ) : (
            <Redirect to={`/design-profile/${designProfilePaths[0].path}`} />
          )
        }
      />
      <Route path="/rescue-anonymous-session">
        <Lazy
          getImport={() =>
            import("../rescueAnonymousSession/RescueAnonymousSession")
          }
          componentProps={{}}
        />
      </Route>
      <Route path="/confirm-payment">
        <Lazy
          getImport={() => import("../../pages/purchase/ConfirmPayment")}
          componentProps={{}}
        />
      </Route>
      <Route
        path="/create-account"
        component={() => {
          if (!user) {
            return (
              <CenterFullScreen>
                <GenericLoadingSpinner />
              </CenterFullScreen>
            );
          }
          return (
            <DesignProfileProvider
              user={user}
              designProfileVersionId={currentDesignProfileVersion}
            >
              <PackageCreateAccountPage />
            </DesignProfileProvider>
          );
        }}
      />
      <Route
        path="/purchase"
        component={() => {
          if (!user) {
            return (
              <CenterFullScreen>
                <GenericLoadingSpinner />
              </CenterFullScreen>
            );
          }

          return (
            <DesignProfileProvider
              user={user}
              designProfileVersionId={currentDesignProfileVersion}
            >
              <PackageFlowCheckoutPage />
            </DesignProfileProvider>
          );
        }}
      />
      <Route
        path="/payment-confirmed"
        component={() => <PackageFlowPaymentConfirmedPage />}
      />
      <Route
        path={`/login`}
        component={(routeProps: RouteChildrenProps) => {
          return (
            <Lazy
              componentProps={routeProps}
              getImport={() => import("../LoginPage")}
            />
          );
        }}
      ></Route>
      <Route path="/packages" component={() => <PackagesGuard />} />
      <Route
        path="/redeem"
        component={(props: RouteChildrenProps) => {
          return (
            <Lazy
              componentProps={props}
              getImport={() => import("../giftCards/GiftCardRedemptionWrapper")}
            />
          );
        }}
      />
      <Route
        exact
        path="/force-logout"
        render={() => {
          forceLogoutViaPath();
          return <Redirect to="/" />;
        }}
      />
      <ProtectedRoute
        path="/onboard"
        component={() => {
          if (!user) {
            return (
              <CenterFullScreen>
                <GenericLoadingSpinner />
              </CenterFullScreen>
            );
          }
          return (
            <DesignProfileProvider
              user={user}
              designProfileVersionId={currentDesignProfileVersion}
            >
              <OnboardRouter />
            </DesignProfileProvider>
          );
        }}
      />
      <ProtectedRoute
        path="/purchase-additional-revision"
        component={(props: RouteChildrenProps) => {
          return (
            <Lazy
              componentProps={props}
              getImport={() => import("../checkout/PurchaseNewRevisionWrapper")}
            />
          );
        }}
      />
      <Route render={() => <HandleIncorrectRoute />} />
    </Switch>
  );
};

export { AppRouter };
export default AppRouter;
